h2.dashboard{
    button{
        margin-top: 0.5rem;
        @include md{
            margin-top: 0;
        }
    }
}

.nav-tabs{
    border-bottom: 1px solid #D0CDCD !important;
    button.nav-link{
        font-family: 'Readex Pro', sans-serif;
        color: $dark_grey;
        font-size: 16px;
        font-weight: 600;
        width: 140px;
        text-align: left;
        height: 45px;
        padding: 0;
        margin-right: 20px;
        border: none;
        line-height: 16px;
        flex-direction: column;
        display: flex;
        justify-content: flex-end;
        border-bottom: 2px solid transparent;
        padding-bottom: 4px;
        &.active{
            background: transparent !important;
            border-bottom: 2px solid $blue;
        }
    }
}


.tab-pane{
    ul{
        padding-left: 0;
        li{
            font-family: 'Readex Pro', sans-serif;
            padding: 8px 5px 7px 3px;
            line-height: 20px;
            border-bottom: 1px solid #D0CDCD;
            color: $dark_grey;
        
            a{
                color: $dark_grey;
                text-decoration: none;
                &:hover{
                    color: $orange;
                }
            }
            .prog-name-link{
                width: 70%;
            }
            .prog-name{
                font-weight: 300;
               
            }
            .theme{
                color: $blue;
                font-size: 12px;
                line-height: 15px;
                font-weight: 100;
                text-transform: uppercase;
               
            }
            @include under-md{
                flex-direction: column !important;
                align-items: start !important;
                .prog-name-link{
                    width: auto !important;
                }
                .meta{
                    width: auto !important;
                }
                .link{
                    margin-left: 1rem;
                    a:before{
                        content: "VIEW ";
                        font-size: 12px;
                        color: $blue;
                    }
                    a:hover:before{
                        color: $orange;
                    }
                }
                svg{
                    
                    
                }
            }
        }
    }
}