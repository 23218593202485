
// Small tablets and large smartphones (landscape view)
$screen-sm-min: 576px;

// Small tablets (portrait view)
$screen-md-min: 768px;

// Tablets and small desktops
$screen-lg-min: 992px;

// Large tablets and desktops
$screen-xl-min: 1200px;

$screen-xxl-min: 1400px;

@mixin under-xl{
	@media (max-width: #{$screen-xl-min - 1}) {
		@content;
	}
}

@mixin under-lg{
	@media (max-width: #{$screen-lg-min - 1}) {
		@content;
	}
}
@mixin under-sm {
    @media (max-width: #{$screen-sm-min - 1}) {
		@content;
	}
}

@mixin under-md {
    @media (max-width: #{$screen-md-min - 1}) {
		@content;
	}
}


// Small devices
@mixin sm {
	@media (min-width: #{$screen-sm-min}) {
		@content;
	}
}

// Medium devices
@mixin md {
	@media (min-width: #{$screen-md-min}) {
		@content;
	}
}

// Large devices
@mixin lg {
	@media (min-width: #{$screen-lg-min}) {
		@content;
	}
}

// Extra large devices
@mixin xl {
	@media (min-width: #{$screen-xl-min}) {
		@content;
	}
}

@mixin xxl {
	@media (min-width: #{$screen-xxl-min}) {
		@content;
	}
}
