@import './styles/mixins.scss';
@import './styles/_colors.scss';
@import './styles/_typography.scss';
@import './styles/_navbar.scss';
@import './styles/_chart.scss';
@import './styles/_tabs.scss';
@import './styles/_map.scss';
@import './styles/_bootstrap.scss';
@import "../node_modules/bootstrap/scss/bootstrap";
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&family=Readex+Pro:wght@300;500;600&display=swap');



.bg-dark{
    background-color: $blue !important;
}


::root{
    --bs-border-radius: 500px;
}
