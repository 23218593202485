$primary:       $orange;
$secondary:     $blue;
$success:       $green;
$info:          $blue;
$warning:       $orange;
$danger:        red;
$light:         $light_grey;
$dark:          $dark_grey;

$heading-color: $dark_grey;
$body-color: $dark_grey;


$btn-color: #fff;
$btn-border-radius: 30px;
$btn-font-size: 16px;
$btn-padding-x: 26px;
$btn-padding-y: 8px;
$btn-border-width: 2px;

$card-border-width: 0;

.text-bg-light{
    color: $dark_grey !important;
    h2.dashboard{
        color: $dark_grey !important;
    }
}

.text-green{
    color: $green;
}
.text-orange{
    color: $orange;
}
.text-blue{
    color: $blue;
}



.btn-primary{
    color: #fff !important;
    border-color: #fff !important;
    &:hover{
        background: #E57600 !important;
    }
}


.w-10{
    width: 10%;
}
.w-30{
    width: 30%;
}

$navbar-padding-y: 20px;

.pre-scrollable .tab-pane{
    max-height: 350px;
    overflow-y: scroll;
    padding-right: 20px;
}



.card-text{
    font-size: 18px;
}
.card-body{
    padding: 0 !important;
}

.card{
    &.border-end{
        border-right-width: 2px !important;
    }
    &.border-start{
        border-left-width: 2px !important;
    }

    @include under-md{
        &.border-end{
            border-right-width: 0px !important;
        }
        &.border-start{
            border-left-width: 0px !important;
        }
        margin-bottom: 20px;
    }

    @include under-xl{
        &.border-xl-end{
            border-right-width: 0px !important;
        }
    }
    
}

h2.dashboard{
    @include under-md{
        flex-direction: column !important;
        align-items: start !important;
    }
}


