body{
    font-weight: 300;
    font-family: 'Readex Pro', sans-serif;
}

h1, h2, h3, h4, h5, h6{
    font-family: 'Montserrat', sans-serif;
}

h1{
    font-size: 48px;
    font-weight: 500;
    &.dashboard{
        font-size: 35px;
        color: #fff;
        margin: 0;
        .emph{
            font-weight: bold;
        }
    }
}
h2{
    font-size: 36px;
    font-weight: 400;
    &.dashboard{
        font-size: 26px;
        font-weight: 400;
        .emph{
            font-weight: 600;
        }
        span.source{
            font-size: 12px;
            font-weight: 300;
            font-family: 'Readex Pro', sans-serif;
        }
    }
}
h3{
    font-size: 28px;
    font-weight: 500;
}
h4{
    font-size: 24px;
    font-weight: bold;
}
h5{
    font-size: 20px;
    font-weight: bold;
}
.card h5{
    font-family: 'Readex Pro', sans-serif;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0;
}
h6{
    font-size: 18px;
    font-weight: bold;
}

