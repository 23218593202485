button.refresh-data{
    background-image: url('../refresh.svg');
    background-repeat: no-repeat;
    background-position-y: 50%;
    background-position-x: 15px;
    padding-left: 51px;
    &:hover{
        background-image: url('../refresh-hover.svg');
        color: $blue;
    }
}


label{
    font-size: 18px;
    font-weight: 600;
    font-family: 'Readex Pro', sans-serif;
}

.navbar-dark{
    > .row{
        flex-grow: 1;
    }
    
    text-align: center;
    .col-12{
        //margin-bottom: 20px;
    }
    @include md{
        text-align: left;
       
        h1{
            margin-right: 1rem;
        }
        .form-select{
            max-width: 200px;
        }
    }
    @include xl{
        .col-12{
            //margin-bottom: 0;
        }
    }

    .navbar-brand{
        margin-right: 0 !important;
        @include md{
            margin-right: 1.5rem !important;
        }
    }
}