.mapboxgl-popup {
    
    .mapboxgl-popup-content{
        padding: 9px 16px;
        border-radius: 5px;
        box-shadow: -5px 5px 8px #00000029;
        .mapboxgl-popup-close-button{
            display: none;
        }
        h3{
            font-size: 14px;
            font-weight: 500;
            font-family: 'Readex Pro', sans-serif;
        }
        .meta-item{
            border-left: 2px solid $orange;
            padding-left: 8px;
            margin-bottom: 8px;
            font-size: 14px;
                line-height: 18px;
            h6{
                font-size: 14px;
                margin-bottom: 0;
                line-height: 18px;
            }
        }
        .website{
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 0.14px;
            a{
                display: inline-block;
                margin-left: 5px;
                text-decoration: none;
                color: $dark_grey;
                &:hover{
                    color: $orange;
                }
            }
            img{
                vertical-align: middle;
            }
        }
    }
}


.map-wrapper{
    min-height: 645px;
}

.map-key{
    padding: 8px 10px;
    top: 10px;
    left: 10px;
    @include lg{
        top: 26px !important;
        left: 56px;
        font-size: 18px;
        padding: 15px 30px;
    }
    
    background: rgba(56, 53, 53, 0.8);
    color: #fff;
    
    font-weight: 500;
   
    svg{
        margin-right: 11px;
    }
}